<template>
  <v-app style="background-color: #f3f7ff">
    <!-- System Bar For Dev Env -->
    <SystemBar v-if="mode == 'DEV' || mode == 'PRODTEST'"></SystemBar>
    <!-- System Bar For Dev Env -->

    <!-- App Toolbar -->
    <appToolbar v-if="$store.state.authStore.userLoggedIn" />
    <!-- App Toolbar -->

    <!-- App Drawer -->
    <appDrawer v-if="$store.state.authStore.userLoggedIn" />
    <!-- App Drawer -->

    <!-- Erro Pop Up -->
    <appErrorPopUp />
    <!-- Erro Pop Up -->

    <!-- Snackbar -->
    <appSnackbar />
    <!-- Snackbar -->

    <!-- Router View  -->
    <v-main v-if="checkingStatus" class="fill-height">
      <v-container fluid class="pa-0 ma-0 fill-height">
        <v-row>
          <v-col class="text-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- Router View  -->

    <!-- Router View  -->
    <v-main class="fill-height" v-else  style="background-color: #eff4fa">
      <div
        class=" d-flex flex-column fill-height"
        :class="{'justify-center': $route.path === '/sign-up', 'justify-space-between': $route.path !== '/sign-up'}"
      >
      <v-container fluid class="py-0 px-md-10 px-3 px-sm-5">
        <!-- fill-height  -->
        <v-row justify="center">
          <v-col md="12">
            <div class="pa-0">
              <v-slide-y-reverse-transition>
                <router-view v-show="show" style="height: 100%" />
              </v-slide-y-reverse-transition>
            </div>
          </v-col>
        </v-row>
          <div
            v-if="sidePanelStatus && $store.state.authStore.userLoggedIn"
              style="
                position: absolute;
                height: 88vh;
                background-color: white;
                width: 100%;
                border-radius: 12px;
                overflow: scroll;
                top: 0;
                right: 0;
                max-width: 300px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
              "
              class=" notification-wrap"
            >
              <p class="d-flex justify-space-between text-body-2 px-3 pt-3" style="position: sticky; top:0; z-index: 1; background-color: white;">
                Notifications
                <v-icon @click="closeNotification()" color="primary">
                mdi-close</v-icon>
              </p>
              <div class="px-3 pb-3">
                <v-card
                  v-for="(notification, i) in notifications?.data || [] "  :key="i+'a'"
                  :class="`mx-auto my-1 p-2 ${!notification.isRead ? 'unread' : ''}`"
                  :title="notification.notificationText"
                  :subtitle="notification.notificationDescription"
                  link
                  @click="readNotification(notification?._id, notification?.uniqueId, notification?.type)"
                >
                  <v-card-item class="pb-1">
                    <v-card-subtitle class="pb-1 d-flex justify-space-between">
                      <span class="text-truncate ">{{notification.notificationText}}</span> <span>{{ formatTimeAgo(notification.createdAt) }}</span>
                    </v-card-subtitle>
                  </v-card-item>
                  <v-card-text class="pt-0">{{notification.notificationDescription}}</v-card-text>
                </v-card>
                <div class="text-center py-3">
                  <v-btn v-if="notifications?.currentPage < notifications?.totalPages" @click="loadMore(notifications.currentPage)">Load more</v-btn>
                </div>
              </div>
            </div>
      </v-container>
      <FooterComponent v-if="!$route?.meta?.hideFooter"/>
      </div>
    </v-main>
    <!-- Router View  -->
  </v-app>
</template>

<script>
import { jwtDecode } from "jwt-decode";
import router from "./router";

export default {
  name: "App",
  components: {
    SystemBar: () => import("@/components/core/SystemBar.vue"),
    appErrorPopUp: () => import("./components/core/appErrorPopUp.vue"),
    appToolbar: () => import("@/components/core/appToolbar.vue"),
    appDrawer: () => import("@/components/core/appDrawer.vue"),
    appSnackbar: () => import("@/components/core/appSnackbar.vue"),
    FooterComponent: () => import("./components/core/FooterComponent.vue"),
  },
  data: () => ({
    show: false,
    mode: process.env.VUE_APP_MODE,
    checkingStatus: true,
  }),
  watch: {
    $route() {
      this.$store.commit('toggleLeftSidePanelStatus', false);
    }
  },
  computed: {
    notifications: {
      get() {
        let data = this.$store.state.usersStore.notifications || {};
        return data;
      },
    },
    
    sidePanelStatus: {
      get() {
        return this.$store.state.leftSidePanelStatus;
      },
    },
  },

  async created() {
    this.show = true;
    this.checkingStatus = true;
    if (
      localStorage.getItem("accessToken") == null ||
      localStorage.getItem("userInfo") == null
    ) {
      // Update User Status to Logout
      this.$store.commit("authStore/changeUserState", false);
      this.checkingStatus = false;
    } else {
      // Update User Status to Logged in
      if (jwtDecode(localStorage.getItem("accessToken")).app_type == 1) {
        this.$store.commit("authStore/changeUserState", true);
        this.$store.commit(
          "authStore/setUser",
          JSON.parse(localStorage.getItem("userInfo"))
        );
        this.$store.commit(
          "authStore/setAccessToken",
          localStorage.getItem("accessToken")
        );
        this.$store.commit(
          "authStore/setCurrentSubscription",
          jwtDecode(localStorage.getItem("accessToken"))
        );
        this.checkingStatus = false;
      } else {
        this.$store.commit("authStore/changeUserState", false);
        this.$store.commit("authStore/clearAll");
        router.push("/login");
        this.checkingStatus = false;
      }
    }
  },
   methods: {
    formatTimeAgo(date) {
      const now = new Date();
      const diffMs = now - new Date(date);
      const diffMins = Math.floor(diffMs / 60000); // Convert milliseconds to minutes
      const diffHours = Math.floor(diffMins / 60);
      const diffDays = Math.floor(diffHours / 24);

      if (diffMins < 60) {
        return diffMins === 1 ? "1 min" : `${diffMins} mins`;
      } else if (diffHours < 24) {
        return diffHours === 1 ? "1 hour" : `${diffHours} hours`;
      } else {
        return diffDays === 1 ? "1 day" : `${diffDays} days`;
      }
    },
    closeNotification(){
      this.$store.commit('toggleLeftSidePanelStatus')
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    loadMore(currectPage) {
      this.getNotification(currectPage+1)
    },
    async getNotification(page = 1) {
        this.loading = true;
        try {
          let response = await this.$store.dispatch(
            "usersStore/getNotifications", {page: page}
          );
          if (response.data.success) {
            this.loading = false;
          }
        } catch (error) {
          console.error("Error:", error);
        }
      },
    async readNotification(id, uniqueId, redirectTo){
        this.loading = true;
        try {
          let response = await this.$store.dispatch(
            "usersStore/readNotifications", id
          );
          if (response.data.success) {
            this.loading = false;
            this.closeNotification()
            switch (redirectTo) {
              case 'requirement_submitted_property':
              case 'brand_requirement_detail':
              return this.$router.push(`/notification-redirect?redirectUrl=/brand-requirements/${uniqueId}`);
              case 'property_detail':
              return this.$router.push(`/notification-redirect?redirectUrl=/properties/${uniqueId}`);
              case 'user_details':
              return this.$router.push(`/notification-redirect?redirectUrl=/users/${uniqueId}`);
              default:
                console.warn(`Unhandled redirectTo case: ${redirectTo}`);
                return;
            }
          }
        } catch (error) {
          console.error("Error:", error);
        }
    }
  }
};
</script>
<style>
.unread{
  background: #d7d7d7 !important;
}

.notification-wrap{
  height: 88vh;
  background-color: white;
  width: 100%;
  border-radius: 12px;
  overflow: scroll;
  font-size: 10px;
}

.notification-wrap .v-card__subtitle, .notification-wrap .v-card__text{
  font-size: 12px !important;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
</style>
